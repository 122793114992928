import { Component, OnInit } from '@angular/core';
import { WorkersService } from '../workers.service';
import { Router } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';

@Component({
  selector: 'app-workers-layout',
  templateUrl: './workers-layout.component.html',
  styleUrl: './workers-layout.component.scss'
})
export class WorkersLayoutComponent implements OnInit {

  public allWorkers: any;

  public workersArr: any;

  constructor(private _workersService: WorkersService, private _router: Router) {

  }

  ngOnInit() {
    this.allWorkers = this._workersService.getSavedWorkers();

    if (this.allWorkers.length <= 0) {
      this._router.navigate(['/staff/workers/list']);
    }

    let i = 0;
    let tempArr: any[] = [];
    this.workersArr = [];

    this.allWorkers.forEach((w: any) => {
      tempArr.push(w);
      i++;
      if (i === 4) {
        this.workersArr.push(tempArr)
        i = 0;
        tempArr = [];
      }
    });

    if (tempArr.length > 0) {
      this.workersArr.push(tempArr);
    }
  }

  print() {

    let elemsArr = []

    for (let i = 0; i < this.workersArr.length; i++) {
      elemsArr.push('print-layout-' + i);
    }
    this.printElem(elemsArr);

  }

  printElem(elems: any[]) {
    const mywindow = window.open('', '', 'height=768,width=1024');

    mywindow?.document.write('<html><head>');
    mywindow?.document.write(document.getElementsByTagName('head')[0].innerHTML);
    mywindow?.document.write('<head><body style="background-color:white !important;">');
    elems.forEach(e => {
      mywindow?.document.write(document.getElementById(e)?.innerHTML ?? '');
    });
    mywindow?.document.write('</div></body></html>');

    mywindow?.document.close();
    mywindow?.focus();

    setInterval(() => {
      mywindow?.print();
      mywindow?.close();
    }, 2000);
  }

  back() {
    this._router.navigate(['/staff/workers/list']);
  }

  generateQRCode(worker: any): string {
    let qrCodeString = "0";
    qrCodeString += "|" + worker.id;
    qrCodeString += "|" + worker.code;
    qrCodeString += "|" + worker.firstName;
    qrCodeString += "|" + worker.lastName;
    qrCodeString += "|" + worker.motherName;
    qrCodeString += "|" + worker.gender;
    qrCodeString += "|" + moment(worker.dateOfBirth).format('YYYY-MM-DD');
    qrCodeString += "|" + worker.mobile;
    qrCodeString += "|" + worker.address;
    qrCodeString += "|" + worker.jobTitle;
    qrCodeString += "|" + worker.workerTypeId;
    qrCodeString += "|" + worker.campId;
    qrCodeString += "|" + moment(worker.employmentDate).format('YYYY-MM-DD');
    qrCodeString += "|" + moment(worker.issueDate).format('YYYY-MM-DD');
    qrCodeString += "|" + moment(worker.expiryDate).format('YYYY-MM-DD');
    qrCodeString += "|" + worker.agencyName;
    qrCodeString += "|" + worker.path;
    qrCodeString += "|" + worker.grandfatherName;
    qrCodeString += "|" + worker.city;
    qrCodeString += "|" + worker.unitFarmId;
    qrCodeString += "|" + worker.enrolType;

    return worker.nfcTag + "*" + qrCodeString;
  }

}
